
/* Section Id */
#inventory {
    padding: 30px 0 20px 0;
    position: relative;
}

.h-inventory-carousel-m1 {
    position: relative;
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    padding-bottom: 15px;
    padding-top: 15px;
}

    .h-inventory-carousel-m1 .section-heading {
        -moz-order: 1;
        -webkit-order: 1;
        -ms-order: 1;
        order: 1;
        min-width: 253px;
        width: 20%;
        padding: 70px 20px 20px 20px;
        color: #fff;
        background: #2c3641;
    }

    .h-inventory-carousel-m1 .grid {
        width: 80%;
        -moz-order: 2;
        -webkit-order: 2;
        -ms-order: 2;
        order: 2;
        min-height: 300px;
    }


    .h-inventory-carousel-m1 [class*='col-'] {
        padding-right: 2px;
        padding-left: 12px;
    }

    .h-inventory-carousel-m1 .section-title {
        margin: 0;
    }


    .h-inventory-carousel-m1 .h-inventory-carousel-btn {
        position: absolute;
        top: 50%;
        left: 20px;
    }

@media screen and (max-width: 768px) {
    .h-inventory-carousel-m1 .h-inventory-carousel-btn {
        top: 14%;
    }
}


@media screen and (max-width: 500px) {
    .h-inventory-carousel-m1 .h-inventory-carousel-btn {
        display: none;
    }
}


.h-inventory-carousel-m1 .h-inventory-carousel-btn .btn {
    font-size: 13px;
    padding: 9px 13px 7px 13px; /* change */
}

.h-inventory-carousel-m1 .h-inventory-carousel-btn .btn-primary,
.h-inventory-carousel-m1 .h-inventory-carousel-btn .btn-primary:focus {
    background-color: transparent;
    color: #fff;
    border-color: #fff;
}

    .h-inventory-carousel-m1 .h-inventory-carousel-btn .btn-primary:hover,
    .h-inventory-carousel-m1 .h-inventory-carousel-btn .btn-primary:active,
    .h-inventory-carousel-m1 .h-inventory-carousel-btn .btn-primary.active {
        background-color: #fff;
        color: #262626;
        border-color: #fff;
    }

    .h-inventory-carousel-m1 .h-inventory-carousel-btn .btn-primary:before {
        background: #fff;
    }

@media(max-width: 992px) {
    .h-inventory-carousel-m1 .section-heading {
        width: 30%;
        min-width: 240px;
    }

    .h-inventory-carousel-m1 .grid {
        width: 70%;
    }
}

@media(max-width: 768px) {
    .h-inventory-carousel-m1 {
        display: block;
    }

        .h-inventory-carousel-m1 .section-heading {
            width: 100%;
            display: block;
            height: auto;
            padding: 20px 20px 70px 20px;
        }

        .h-inventory-carousel-m1 .grid {
            width: 100%;
            display: block;
            padding: 20px 0 0 0;
            text-align: left;
        }

        .h-inventory-carousel-m1 [class*='col-'] {
            padding-right: 3px;
            padding-left: 3px;
        }
}



.h-inventory-carousel-m1 .carousel-control,
.h-inventory-carousel-m1 .carousel-control.left,
.h-inventory-carousel-m1 .carousel-control.right {
    background-image: none;
}

.h-inventory-carousel-m1 .carousel-control {
    line-height: 38px;
    color: #fff;
    background: rgba(0,0,0,0.5);
    width: 40px;
    height: 40px;
    text-align: center;
    vertical-align: middle;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -o-border-radius: 2px;
    position: absolute;
    top: 75%;
    bottom: 0;
    left: 0;
    text-shadow: 0 1px 2px rgba(0,0,0,.6);
    filter: alpha(opacity=100);
    opacity: 1;
}

.h-inventory-carousel-m1 .carousel-control.right {
    left: auto;
    right: 101%;
}

.h-inventory-carousel-m1 .carousel-control.left {
    left: -9%;
}

.h-inventory-carousel-m1 .carousel-control i.glyphicon {
    font-size: 20px !important;
    margin-top: -11px !important;
}

.h-inventory-carousel-m1 .carousel-control.left i.glyphicon {
    margin-left: -13px;
    margin-right: 0;
}

.h-inventory-carousel-m1 .carousel-control.right i.glyphicon {
    margin-right: -15px;
}


@media(max-width: 1400px) {
    .h-inventory-carousel-m1 .carousel-control.right {
        right: 102%;
    }

    .h-inventory-carousel-m1 .carousel-control.left {
        left: -12%;
    }
}

@media(max-width: 1199px) {
    .h-inventory-carousel-m1 .carousel-control.left {
        left: -15%;
    }
}

@media(max-width: 992px) {
    .h-inventory-carousel-m1 .carousel-control.left {
        left: -16%;
    }
}

@media(max-width: 950px) {
    .h-inventory-carousel-m1 .carousel-control.left {
        left: -18%;
    }
}

@media(max-width: 830px) {
    .h-inventory-carousel-m1 .carousel-control.left {
        left: -20%;
    }
}


@media screen and (max-width: 768px) {
    .h-inventory-carousel-m1 .carousel-control {
        top: -70px;
    }

        .h-inventory-carousel-m1 .carousel-control.right {
            right: 18px;
        }

        .h-inventory-carousel-m1 .carousel-control.left {
            left: auto;
            right: 70px;
        }

            .h-inventory-carousel-m1 .carousel-control.left i.glyphicon {
                margin-left: -9px;
                margin-right: 0;
            }

        .h-inventory-carousel-m1 .carousel-control.right i.glyphicon {
            margin-right: -11px;
        }
}

.h-inventory-carousel-m1 .extra1,
.h-inventory-carousel-m1 .extra2,
.h-inventory-carousel-m1 .extra3 {
    display: none;
}

@media only screen and (min-width: 769px) and (-webkit-transform-3d) {
    .h-inventory-carousel-m1 .carousel-item.active.left,
    .h-inventory-carousel-m1 .carousel-item.prev {
        transform: translate3d(-50%, 0, 0);
        left: 0;
    }

    .h-inventory-carousel-m1 .carousel-item.active.right,
    .h-inventory-carousel-m1 .carousel-item.next {
        transform: translate3d(50%, 0, 0);
        left: 0;
    }
}

@media only screen and (min-width: 769px) {

    .h-inventory-carousel-m1 .active.left {
        left: -50%;
    }

    .h-inventory-carousel-m1 .active.right {
        left: 50%;
    }

    .h-inventory-carousel-m1 .next {
        left: 50%;
    }

    .h-inventory-carousel-m1 .prev {
        left: -50%;
    }

    .h-inventory-carousel-m1 .extra1 {
        display: block;
    }
}

@media only screen and (min-width: 1200px) and (-webkit-transform-3d) {
    .h-inventory-carousel-m1 .carousel-item.active.left,
    .h-inventory-carousel-m1 .carousel-item.prev {
        transform: translate3d(-25%, 0, 0);
        left: 0;
    }

    .h-inventory-carousel-m1 .carousel-item.active.right,
    .h-inventory-carousel-m1 .carousel-item.next {
        transform: translate3d(25%, 0, 0);
        left: 0;
    }
}

@media only screen and (min-width: 1200px) {

    .h-inventory-carousel-m1 .active.left {
        left: -25%;
    }

    .h-inventory-carousel-m1 .active.right {
        left: 25%;
    }

    .h-inventory-carousel-m1 .next {
        left: 25%;
    }

    .h-inventory-carousel-m1 .prev {
        left: -25%;
    }

    .h-inventory-carousel-m1 .extra2,
    .h-inventory-carousel-m1 .extra3 {
        display: block;
    }
}




@media (max-width: 1199px) {
    .h-inventory-carousel-m1 .col-md-3 {
        width: 50%;
    }
}


@media (max-width: 768px) {
    .h-inventory-carousel-m1 .col-md-3 {
        width: 100%;
    }

    .h-inventory-carousel-m1 .grid {
        min-height: unset;
    }
}

/* Homepage Vehicle Cards
===========================================================*/

.h-inventory-carousel-m1 .vehicle-card {
    position: relative;
    margin-bottom: 3px;
    min-height: 300px;
}

@media (max-width: 1400px) {
    .h-inventory-carousel-m1 .vehicle-card {
        min-height: 250px;
    }

    .h-inventory-carousel-m1 .grid {
        min-height: 250px;
    }
}

.h-inventory-carousel-m1 .vehicle-card .vehicle-photo {
    position: relative;
    height: 190px;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.h-inventory-carousel-m1 .vehicle-card .vehicle-price {
    /*position: absolute;*/
    width: 100%;
    display: block;
    top: 100%;
    right: 0;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 5px 14px;
}


    .h-inventory-carousel-m1 .vehicle-card .vehicle-price span {
        color: #fff;
        font-size: 12px;
        font-weight: 400;
        padding-left: 8px;
    }

.h-inventory-carousel-m1 .vehicle-card .vehicle-info {
    padding: 10px 15px 35px 15px;
}

    .h-inventory-carousel-m1 .vehicle-card .vehicle-info .vehicle-phone {
        font-size: 16px;
        font-weight: 200;
    }

.h-inventory-carousel-m1 .vehicle-card .vehicle-photo img {
    width: 100%;
    border-bottom: 0 solid #FFF;
}

.h-inventory-carousel-m1 .vehicle-card .vehicle-title {
    font-size: 18px;
    margin-top: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.h-inventory-carousel-m1 .vehicle-card .vehicle-miles {
    font-size: 15px;
    text-align: left;
    margin-top: 5px;
    padding-top: 0;
}

@media(max-width: 768px) {
    .h-inventory-carousel-m1 .vehicle-card .vehicle-price {
        font-size: 18px;
        padding: 8px 14px;
    }

    .h-inventory-carousel-m1 .vehicle-card .vehicle-info {
        padding: 25px 15px 15px 15px;
    }
}

.h-inventory-carousel-m1 .no-vehicles-found {
    width: 500px;
    max-width: 100%;
    background-color: #ffcccc;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    text-align: center;
    border-radius: 3px;
}

/*===================================================================================================
  Map & Directions
===================================================================================================*/
.map-m1 {
    padding: 0;
    overflow: hidden;
    color: #333;
}

.map-m1 .scrolloff {
    pointer-events: none;
}

.map-m1 .map-popup .section-title {
    font-size: 18px;
}

.map-m1 .map-popup .section-subheading {
    font-size: 14px;
    color: #595959;
}

.map-m1 .map-popup .section-title::after {
    display: none;
}

.mapOverlay {
	background:transparent; 
	position:absolute; 
	width:100%;
}


/*===================================================================================================
  Locations / Visit Us
===================================================================================================*/
.locations-m1 .c-table {
    table-layout: fixed;
}

.locations-m1 .locations-section {
    text-align: center;
    padding: 20px;
    line-height: 26px;
    table-layout: fixed;
}

.locations-m1 .locations-section .dealer-header {
    font-size: 20px;
    font-weight: 200;
    margin-bottom: 15px;
	cursor: pointer;
}

.locations-m1 .locations-section address p {
    padding-left: 0;
    text-indent: 0;
    margin-bottom: 3px;
    color: #fff;
}

.locations-m1 .title-line {
    display: none;
}


.locations-m1 .locations-section i.fa-phone:before {
    content: "\f10b";
}

.locations-m1 .locations-section i {
    font-size: 18px;
    vertical-align: top;
    margin-top: 1px;
    margin-right: 3px;
    opacity: .5;
}


@media(max-width: 992px) {
    .locations-m1 .c-tablecell {
        display: block;
        width: 100%;
    }
}


/* section id */
.find {
    position: relative;
    z-index: 4;
}

.find .section-box {
    border-top: 1px solid #f7f7f7;
}


.h-find-m1 .section-heading {
    display: none;
    padding-bottom: 10px;
}

.h-find-m1 .form-group {
    position: relative;
}

.h-find-m1 label {
    position: absolute;
    top: 5px;
    left: 14px;
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .08em;
    z-index: 55001 !important;
}

@media (min-width: 768px) {
    .h-find-m1 {
        padding: 30px 20px 10px 20px;
        background: white;
    }
}

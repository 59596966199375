body.font-Poppins .font-primary {
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    letter-spacing: .05em;
    font-weight: 400;
}

body.font-Poppins .page-title-headings h2,
body.font-Poppins .page-title-headings h1 {
    letter-spacing: 0.05em;
}

body.font-Poppins .vehicle-link .font-primary {
    letter-spacing: .02em;
}

body.font-Poppins .site-logo h2 {
    font-size: 18px;
}

body.font-Poppins .site-logo a {
    font-weight: 700;
}

body.font-Poppins .page-title-headings h2,
body.font-Poppins .page-title-headings h1,
body.font-Poppins .section-title h2,
body.font-Poppins .section-title h1,
body.font-Poppins .section-title {
    font-size: 26px;
}

body.font-Poppins .page-title-headings h2,
body.font-Poppins .page-title-headings h1,
body.font-Poppins .section-title h2,
body.font-Poppins .section-title h1,
body.font-Poppins .section-title,
body.font-Poppins .locations-section .dealer-header,
body.font-Poppins .modal-title,
body.font-Poppins .header-logo a {
    font-weight: 700;
}


body.font-Poppins .staff-member .staff-photo .staff-name,
body.font-Poppins .staff-member .staff-photo .staff-name span {
    font-weight: 400;
}

body.font-Poppins .h-inventory-singlecarousel-m1 .vehicle-card .vehicle-title {
    line-height: 1em;
}

body.font-Poppins .carousel-featured .specs {
    margin-top: 10px;
}

@media (max-width: 768px) {
    body.font-Poppins .h-inventory-singlecarousel-m1 .vehicle-card .vehicle-title {
        line-height: 1.1em;
    }
}

/* section id */
.about {
    margin-top: 0;
    margin-bottom: 0;
    padding: 60px 0 60px 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

@media(max-width: 767px) {
    .about {
        padding: 30px 0 30px 0;
    }
}


.about:after {
    content: '';
    position: absolute;
    top: 0;
    left: -400px;
    width: 80%;
    height: 2000px;
    z-index: 3;
    -webkit-transform: rotate(20deg);
    -moz-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    -o-transform: rotate(20deg);
    transform: rotate(20deg);
    -o-box-shadow: none;
    margin-top: -600px;
    vertical-align: top;
    overflow: hidden;
    background: #ddd;
    -webkit-backface-visibility: hidden;
}


.h-about-m1 {
    position: relative;
    z-index: 9;
}


.h-about-m1 .section-heading {
    padding-bottom: 10px;
}

.h-about-m1 .spotlight {
    -moz-align-items: center;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
}

.h-about-m1 .spotlight .image {
    -moz-order: 1;
    -webkit-order: 1;
    -ms-order: 1;
    order: 1;
    border-radius: 0;
    width: 40%;
}

.h-about-m1 .spotlight .image img {
    border-radius: 0;
    width: 100%;
}

.h-about-m1 .spotlight .content {
    padding: 20px 4em 20px 4em;
    -moz-order: 2;
    -webkit-order: 2;
    -ms-order: 2;
    order: 2;
    width: 60%;
    line-height: 1.7em;
}


.h-about-m1 .spotlight .content .about-text  {
    line-height: 1.8em;
    font-size: 13px;
    margin-bottom: 25px;
    height: 134px;
    display: -webkit-box;
    max-width: 4400px;
    -webkit-line-clamp:6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


@media screen and (max-width: 1280px) {
    .h-about-m1 .spotlight .image {
        width: 45%;
    }

    .h-about-m1 .spotlight .content {
        width: 55%;
        padding: 30px 40px 30px 40px;
    }
}

@media screen and (max-width: 992px) {

    .h-about-m1 .section-heading {
        padding-bottom: 0;
    }

    .h-about-m1 .section-title {
        margin-top: 0;
    }

    .h-about-m1 .spotlight .content p {
        margin-bottom: 10px;
    }

    .h-about-m1 .spotlight .content {
        padding: 10px 30px 10px 30px;
    }
}


@media screen and (max-width: 768px) {
    .h-about-m1 .spotlight {
        display: block;
    }

    .h-about-m1 .spotlight .image {
        width: 100%;
    }

    .h-about-m1 .section-heading {
        padding-bottom: 10px;
    }

    .h-about-m1 .section-title {
        margin-top: 20px;
    }


    .h-about-m1 .spotlight .content {
        max-width: none;
        text-align: left;
        width: 100%;
        padding: 15px 40px 35px 40px;
    }
}

@media screen and (max-width: 736px) {
    .h-about-m1 .spotlight .content {
        padding: 10px 30px 30px 30px;
    }
}

body.font-Montserrat .font-primary,
body.font-Montserrat .vehicle-card .vehicle-photo .vehicle-price {
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: .04em;
}

body.font-Montserrat .page-title-headings h2,
body.font-Montserrat .page-title-headings h1,
body.font-Montserrat .section-title h2,
body.font-Montserrat .section-title h1,
body.font-Montserrat .section-title,
body.font-Montserrat .locations-section .dealer-header,
body.font-Montserrat .modal-title,
body.font-Montserrat .header-logo a {
    letter-spacing: .04em;
    font-weight: 700;
}

body.font-Cabin .font-primary {
    font-family: 'Cabin', sans-serif;
    text-transform: uppercase;
    letter-spacing: .05em;
    font-weight: 700;
}

body.font-Cabin .page-title-headings h2,
body.font-Cabin .page-title-headings h1 {
    letter-spacing: 0.05em;
}

body.font-Cabin .vehicle-link .font-primary {
    letter-spacing: .02em;
}

body.font-Cabin #carousel-featured .specs {
    margin-top: 15px;
}

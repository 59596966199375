/* Standard view */

.seonamedlinks-module .column ul li {
    list-style: none;
    margin-bottom: 10px;
    font-size: 14px;
    cursor: pointer;
}

.seonamedlinks-module .count {
    font-size: 12px;
}

.seonamedlinks-module .column {
    -webkit-column-width: 170px;
    -moz-column-width: 170px;
    column-width: 170px;
    width: 80%;
    -moz-order: 2;
    -webkit-order: 2;
    -ms-order: 2;
    order: 2;
    background: #fff;
    padding: 50px 30px 50px 30px;
}

.seonamedlinks-module {
    position: relative;
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    padding-bottom: 2px;
    padding-top: 0;
}

    .seonamedlinks-module .section-heading {
        -moz-order: 1;
        -webkit-order: 1;
        -ms-order: 1;
        order: 1;
        width: 20%;
        min-width: 253px;
        padding: 30px 20px 60px 20px;
        color: #fff;
        background-color: #0083ae;
        background-image: url('/images/wheel.png');
        min-height: 100%;
        background-position: bottom right;
        background-size: 150px 150px;
        background-repeat: no-repeat;
    }

    .seonamedlinks-module .section-title {
        margin: 0;
    }

@media(max-width: 992px) {
    .seonamedlinks-module .section-heading {
        width: 30%;
        min-width: 240px;
        background-size: 160px 160px;
    }

    .seonamedlinks-module .column {
        width: 70%;
        padding: 40px 20px 40px 20px;
    }
}

@media(max-width: 768px) {
    .seonamedlinks-module {
        display: block;
    }

        .seonamedlinks-module .section-heading {
            width: 100%;
            display: block;
            height: auto;
            padding: 20px 20px 23px 20px;
            background-size: 115px 115px;
        }

        .seonamedlinks-module .column {
            width: 100%;
            display: block;
            padding-top: 20px;
            padding-bottom: 20px;
        }
}

/*   END OF STANDARD VIEW */

/*===================================================================================================
  Search by Price List
===================================================================================================*/
.h-price-m1 .column ul li {
    list-style: none;
    margin-bottom: 10px;
    font-size: 14px;
    cursor: pointer;
}

.h-price-m1 .count {
    font-size: 12px;
}

.h-price-m1 .column {
    -webkit-column-width: 170px;
    -moz-column-width: 170px;
    column-width: 170px;
    width: 80%;
    -moz-order: 2;
    -webkit-order: 2;
    -ms-order: 2;
    order: 2;
    background: #fff;
    padding: 50px 30px;
}

/* section id */
#price {
    padding-bottom: 30px;
}

.h-price-m1 {
    position: relative;
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    padding-bottom: 2px;
    padding-top: 0;
}

.h-price-m1 .section-heading {
    -moz-order: 1;
    -webkit-order: 1;
    -ms-order: 1;
    order: 1;
    width: 20%;
    min-width: 253px;
    padding: 30px 20px 60px 20px;
    color: #fff;
    background-color: #838383;
    background-image: url('/images/search.png');
    min-height: 100%;
    background-position: bottom right;
    background-size: 150px 150px;
    background-repeat: no-repeat;    
}

.h-price-m1 .section-title {
    margin: 0;
}

@media(max-width: 992px) {
    .h-price-m1 .section-heading {
        width: 30%;
        min-width: 240px;
        background-size: 160px 160px;
    }

    .h-price-m1 .column {
        width: 70%;
        padding: 40px 20px 40px 20px;
    }
}

@media(max-width: 768px) {
    .h-price-m1 {
        display: block;
    }

    .h-price-m1 .section-heading {
        width: 100%;
        display: block;
        height: auto;
        padding: 20px 20px 23px 20px;
        background-size: 115px 115px;
    }

    .h-price-m1 .column {
        width: 100%;
        display: block;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

body.font-Roboto .font-primary {
    font-family: 'Roboto Slab', serif;
    text-transform: none;
    letter-spacing: .02em;
    font-weight: 400;
}

body.font-Roboto .page-title-headings h2,
body.font-Roboto .page-title-headings h1 {
    letter-spacing: 0.02em;
}

body.font-Roboto .btn {
    text-transform: none;
}

body.font-Roboto .section-title {
    letter-spacing: .02em;
}

body.font-Roboto .h-inventory-singlecarousel-m1 .vehicle-card .vehicle-title {
    line-height: .9em;
}

body.font-Roboto .carousel-featured .specs {
    margin-top: 10px;
}

@media (max-width: 768px) {
    body.font-Roboto .h-inventory-singlecarousel-m1 .vehicle-card .vehicle-title {
        line-height: 1.1em;
    }
}

body.font-Volkhov .font-primary {
    font-family: 'Volkhov', serif;
    font-weight: 400;
    text-transform: none;
    letter-spacing: 0.06em;
}

body.font-Volkhov .btn {
    font-size: 15px;
}

body.font-Volkhov .btn-xl {
    font-size: 16px;
}

body.font-Volkhov .btn-xlg {
    font-size: 18px;
}

body.font-Volkhov .btn-super {
    font-size: 17px;
}

body.font-Volkhov .btn-sm {
    font-size: 15px;
}

body.font-Volkhov .staff-member .staff-photo .staff-name {
    font-size: 16px;
}

body.font-Volkhov .section-title,
body.font-Volkhov .page-title-headings h2,
body.font-Volkhov .page-title-headings h1,
body.font-Volkhov .locations-section .dealer-header,
body.font-Volkhov .vehicle-title,
body.font-Volkhov .vehicle-card .vehicle-title,
body.font-Volkhov .site-logo h2,
body.font-Volkhov .hero-overlay h2,
body.font-Volkhov .section-title h2,
body.font-Volkhov .section-title h1,
body.font-Volkhov .dealer-name,
body.font-Volkhov .modal-title,
body.font-Volkhov .staff-member .staff-photo .staff-name,
body.font-Volkhov .staff-member .staff-photo .staff-name span,
body.font-Volkhov .header-logo a {
    text-transform: none;
    letter-spacing: 0.05em;
    font-weight: 400 !important;
}

body.font-Volkhov .h-inventory-singlecarousel-m1 .vehicle-card .vehicle-title {
    font-size: 50px;
    line-height: 1em;
}

body.font-Volkhov .carousel-featured .specs {
    margin-top: 10px;
}


@media(max-width: 1200px) {
    body.font-Volkhov .h-inventory-singlecarousel-m1 .vehicle-card .vehicle-title {
        font-size: 40px;
        line-height: 1em;
    }
}

@media(max-width:900px) {
    body.font-Volkhov .h-inventory-singlecarousel-m1 .vehicle-card .vehicle-title {
        font-size: 34px;
    }
}

@media screen and (max-width: 768px) {
    body.font-Volkhov .h-inventory-singlecarousel-m1 .vehicle-card .vehicle-title {
        font-size: 23px;
        line-height: 1em;
    }
}

/*===================================================================================================
  Top Nav Bar
===================================================================================================*/

.nav-m1 .menu {
    padding: 0;
}

.nav-m1 .main-menu {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    position: relative;
    width: 100%;
    top: 0;
    z-index: 8;
}

.nav-m1 .main-menu.affix {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 49000;
}


/*===================================================================================================
  Navigation
===================================================================================================*/
.nav-m1 .navbar {
    border: 0;
    min-height: 60px;
    z-index: 5000;
}

.nav-m1 .navbar-nav > li a {
    display: inline-block;
    letter-spacing: 0.04em;
    font-size: 13px;
    font-weight: 700;
    padding: 18px 20px;
    color: #2c3641;
    margin-top: -3px;
    text-decoration: none;
    text-transform: uppercase;
    line-height: 1em;
    position: relative;
}

.nav-m1 .navbar-nav > li > a:after {
    content: '';
    margin: 0 auto;
    border-top: 3px solid #0083ae;
    position: absolute;
    opacity: 0;
    top: 0.25em;
    left: 50%;
    right: 50%;
    -webkit-transition: left 250ms ease-in-out, right 250ms ease-in-out;
    -moz-transition: left 250ms ease-in-out, right 250ms ease-in-out;
    -o-transition: left 250ms ease-in-out, right 250ms ease-in-out;
    transition: left 250ms ease-in-out, right 250ms ease-in-out;
}

.nav-m1 .navbar-nav > li > a:hover:after {
    opacity: 1;
    left: 12px;
    left: 0.75rem;
    right: 12px;
    right: 0.75rem;
    -webkit-transition: left 100ms ease-in-out, right 100ms ease-in-out;
    -moz-transition: left 100ms ease-in-out, right 100ms ease-in-out;
    -o-transition: left 100ms ease-in-out, right 100ms ease-in-out;
    transition: left 100ms ease-in-out, right 100ms ease-in-out;
}

/* Disable transition for touch screens */
@media (pointer: coarse) and (hover: none) {
    .nav-m1 .navbar-nav > li > a:after,
    .nav-m1 .navbar-nav > li > a:hover:after {
        transition: none;
    }
}

.nav-m1 .navbar-nav > li a:hover,
.nav-m1 .navbar-nav > li a:focus,
.nav-m1 .navbar-nav > li a:active,
.nav-m1 .navbar-nav > li.active > a,
.nav-m1 .navbar-nav .dropdown-menu a.dropdown-item.active {
    background-color: transparent;
}

.nav-m1 .navbar-nav > li.active > a:after {
    opacity: 1;
    left: 12px;
    left: 0.75rem;
    right: 12px;
    right: 0.75rem;
}


.nav-m1 .navbar li a,
.nav-m1 .navbar li a:hover,
.nav-m1 .navbar li a:focus,
.nav-m1 .navbar li a.active,
.nav-m1 .navbar li a:active {
    font-weight: 700;
}

.nav-m1 .navbar-collapse {
    padding: 0;
}


/* Site Logo - Visible in Mobile Size */

.nav-m1 .site-logo h2 {
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 16px;
    padding-left: 15px;
    text-transform: uppercase;
}

.nav-m1 .site-logo a {
    color: #fff;
}

.nav-m1 .site-logo a:hover {
    text-decoration: none;
    color: #262626;
}

.nav-m1 .site-logo img {
    height: 50px;
    padding-left: 1rem;
}

/* Call Button - Visible in Mobile Size */
.nav-m1 .call-xs i {
    font-size: 1.5em;
}



.nav-m1 .headernavigation .btn-primary,
.nav-m1 .headernavigation .btn-primary:focus {
    background: transparent;
    color: #262626;
    border-color: #262626;
}

.nav-m1 .headernavigation .btn-primary:hover,
.nav-m1 .headernavigation .btn-primary:active,
.nav-m1 .headernavigation .btn-primary.active {
    background-color: #262626;
    color: #fff;
    border-color: #262626;
    background-image: none;
}

.nav-m1 .headernavigation .btn-primary:before {
    background: #262626;
}


/* Center Links in Desktop View */
@media (min-width: 768px) {
    .nav-m1 .container-fluid > .navbar-collapse,
    .nav-m1 .container-fluid > .navbar-header,
    .nav-m1 .container > .navbar-collapse,
    .nav-m1 .container > .navbar-header {
        margin-right: -15px;
        margin-left: -15px;
    }

    .nav-m1 .navbar-nav {
        float: none;
        margin: 0 auto;
        text-align: center;
    }

    .nav-m1 .navbar-nav > li {
        float: none;
        display: inline-block;
        margin-right: -4px;
    }
}





@media screen and (max-width: 767px) {
    .nav-m1 {
        background-color: #0083ae;
    }

    .nav-m1 .menu {
        padding: 15px;
    }

    .nav-m1 .main-menu {
        background-color: #0083ae;
        border-bottom: 2px solid rgba(0,0,0,0.1);
    }

    .nav-m1 .navbar-collapse.in {
        background-color: #fff;
    }

    .nav-m1 .navbar-collapse.collapsing {
        background-color: #fff;
    }

    .nav-m1 .navbar-header ul {
        margin-bottom: 0;
    }

    .nav-m1 .navbar-collapse {
        border-top: 1px solid #363636;
        padding: 15px;
    }

    .nav-m1 .navbar-nav {
        margin: 0;
    }

    .nav-m1 .navbar-nav > li > a {
        padding: 15px 10px;
        display: block;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
    }

    .nav-m1 .menu-list li a,
    .nav-m1 .menu-list li:first-child a {
        border: 0;
    }
}



/* Navbar Toggle
===========================================================*/

.nav-m1 .navbar-toggle {
    position: relative;
    float: right;
    padding: 12px 18px 13px 18px;
    margin: 0;
    border: none;
    background-color: transparent;
    background-image: none;
    border-radius: 0;
    height: 52px;
}

.nav-m1 .navbar-toggle:focus {
    border: 0;
    outline: 0;
}

.nav-m1 .navbar-toggle .icon-bar {
    background-color: #fff;
    position: relative;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.nav-m1 .navbar-toggle.active .icon-bar:nth-of-type(1) {
    top: 6px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.nav-m1 .navbar-toggle.active .icon-bar:nth-of-type(2) {
    background-color: transparent;
}

.nav-m1 .navbar-toggle.active .icon-bar:nth-of-type(3) {
    top: -6px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}



.nav-m1 .main-menu .dropdown-menu {
    border: 0
}


.nav-m1 .navbar-nav .dropdown-menu a.dropdown-item {
    display: block;
    padding: 10px;
    margin: 5px;
}

.dropdown-toggle::before {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

body.font-Khand .font-primary {
    font-family: 'Khand', sans-serif;
    text-transform: uppercase;
    letter-spacing: .05em;
    font-weight: 700;
}

body.font-Khand .page-title-headings h2,
body.font-Khand .page-title-headings h1 {
    letter-spacing: 0.05em;
}

body.font-Khand .vehicle-link .font-primary {
    letter-spacing: .02em;
    line-height: 1.1em;
    margin-top: 0;
}

body.font-Khand .btn {
    font-size: 18px;
}

body.font-Khand .btn-xl {
    font-size: 18px;
}

body.font-Khand .btn-xlg {
    font-size: 23px;
}

body.font-Khand .btn-super {
    font-size: 18px;
}

body.font-Khand .btn-sm {
    font-size: 15px;
}


body.font-Khand .vehicle-title {
    font-size: 28px;
}

body.font-Khand .vehicle-price {
    font-size: 20px;
}

body.font-Khand .modal-title {
    font-size: 20px;
}


body.font-Khand .site-logo h2 {
    margin-top: 13px;
    font-size: 22px;
    font-weight: 400;
}


body.font-Khand .staff-member .staff-photo .staff-name {
    font-size: 18px;
}

body.font-Khand .staff-member .staff-photo .staff-name span {
    font-size: 14px;
}


body.font-Khand .section-title,
body.font-Khand .section-box-title,
body.font-Khand .vehicle-title,
body.font-Khand .vehicle-price,
body.font-Khand .dealer-name,
body.font-Khand .staff-member .staff-photo .staff-name,
body.font-Khand .staff-member .staff-photo .staff-name span,
body.font-Khand .section-title,
body.font-Khand .section-title h2,
body.font-Khand .section-title h1,
body.font-Khand .page-title-headings h2,
body.font-Khand .page-title-headings h1 {
    font-weight: 400;
}

body.font-Khand .h-inventory-carousel-m1 .section-title,
body.font-Khand .seonamedlinks-module .section-title,
body.font-Khand .h-price-m1 .section-title,
body.font-Khand .h-looking-m1 .section-title {
    font-weight: 700;
    font-size: 34px;
    line-height: 1em;
}



body.font-Khand .h-inventory-singlecarousel-m1 .vehicle-card .vehicle-title {
    line-height: .9em;
}

body.font-Khand .carousel-featured .specs {
    margin-top: 10px;
}

@media (max-width: 768px) {
    body.font-Khand .h-inventory-singlecarousel-m1 .vehicle-card .vehicle-title {
        line-height: 1.1em;
    }
}

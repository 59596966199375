/*===================================================================================================
  Global Features
===================================================================================================*/
body {
    font-weight: 400;
    color: #262626;
    position: relative;    
    overflow-x: hidden;
}

/* Links & Buttons
===========================================================*/
a {
    color: #262626;
    text-decoration: none;
    font-weight: 400;
}

    a:hover {
        color: #0083ae;
    }

.btn {
    letter-spacing: .04em;
    font-weight: 700;
    overflow: hidden;
    position: relative;
    z-index: 1;
    transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.btn:hover {
    color: #fff;
}

.btn:before {
    content: "";
    position: absolute;
    background: #42454d;
    bottom: 0;
    left: 0;
    right: 150%;
    top: 0;
    z-index: -1;
    transition: right 250ms ease-in-out;
    -o-transition: right 250ms ease-in-out;
    -ms-transition: right 250ms ease-in-out;
    -moz-transition: right 250ms ease-in-out;
    -webkit-transition: right 250ms ease-in-out;
    height: 400px;
    margin-top: -200px;
    margin-left: -100%;
    -webkit-transform: rotate(20deg);
    -moz-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    -o-transform: rotate(20deg);
    transform: rotate(20deg);
}

.btn:hover:before {
    right: 0;
    transition: right 100ms ease-in-out;
    -o-transition: right 100ms ease-in-out;
    -ms-transition: right 100ms ease-in-out;
    -moz-transition: right 100ms ease-in-out;
    -webkit-transition: right 100ms ease-in-out;
}

.btn-xl {
    font-size: 14px;
    padding: 11px 25px 10px 25px;
}


/*TODO: should these button coloring styles be deleted?*/

.btn-default,
.btn-default:focus {
    background-color: #2f3542;
    color: #fff;
    border-color: #2f3542;
}

.btn-default:hover,
.btn-default:active,
.btn-default.active {
    background-color: #85868C;
    color: #fff;
    border-color: #85868C;
}

.btn-default:disabled,
.btn-default.disabled {
    background-color: #E6E6E6;
}

.btn-default:before {
    background: #85868C;
}



.btn-primary,
.btn-primary:focus {
    background-color: #0083ae;
    color: #fff;
    border-color: #0083ae;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary.active {
    background-color: #01516B;
    color: #fff;
    border-color: #01516B;
    background-image: none;
}

.btn-primary:before {
    background: #01516B;
}

.btn-link {
    color: #0083ae;
    text-decoration: none;
    border-bottom: 1px solid #0083ae;
}

.btn-link:hover,
.btn-link:active,
.btn-link.active {
    color: #2f3542;
    text-decoration: none;
    border-bottom-color: #2f3542;
}


/*===================================================================================================
  Form Styles
===================================================================================================*/


/* Modal
===========================================================*/

.modal-heading,
.modal-header {
    background-color: #2f3542;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .04em;
    border-bottom: 1px solid #ddd;
}

.modal-heading .close,
.modal-header .close {
    color: #fff;
}


/* Modal Success
===========================================================*/

.modal-success,
.modal-success .modal-heading,
.modal-success .modal-header {
    background: #0083ae;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.modal-success {
    color: #fff;
    border-bottom: 0;
}

.modal-success h4 {
    font-weight: 400;
    text-transform: none;
}

.modal-success .close {
    color: #fff;
}

.modal-success .close:hover, .modal-success .close:focus, .modal-success .close:active {
    color: #404040;
}


/* Sections
===========================================================*/


.section-box {
    padding: 30px 20px 30px 20px;
    box-shadow: 1px 2px 3px #ABABAB;
}


/* Section Module Padding
===========================================================*/

#h-inventory-carousel-m1 {
    padding: 50px 0;
}
.locations-m1 {
    padding: 10px 0;
}

@media (max-width: 767px) {
    #h-inventory-carousel-m1 {
        padding: 30px 0;
    }
}



/* Sections Titles
===========================================================*/

.section-heading {
    padding-bottom: 30px;
}

.section-title {
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: .04em;
}

.page-title-headings h2,
.page-title-headings h1,
.section-title h2,
.section-title h1,
.section-title,
.locations-section h2,
.modal-title {
    letter-spacing: .04em;
    font-weight: 700;
}


.section-subheading {
    font-size: 16px;
    line-height: 1.3em;
    font-weight: 300;
    margin-top: 10px;
}


.section-heading .section-title-line {
    display: block;
    width: 60px;
    height: 4px;
    background-color: #fff;
    margin-top: 10px;
}


/* Sections Titles
===========================================================*/
.special {
    text-decoration: line-through;
    font-size: 16px;
}


@media (max-width: 768px) {
    .find {
        padding-top:10px;
        padding-bottom:20px;
    }
}
